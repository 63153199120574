<template>
  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a
          href="#"
          aria-label="sidebar mini logo"
          class="simple-text logo-mini"
        >
          <div class="logo-img" :class="{ 'logo-img-rtl': isRTL }">
            <img src="@/assets/images/logo.png" alt="" />
          </div>
        </a>
        <a href="#" class="simple-text logo-normal">
          {{ title }}
        </a>
      </div>
      <slot></slot>
      <ul class="nav">
        <!-- Use the links slot or fallback to default rendering -->
        <slot name="links">
          <SidebarLink
            v-for="(link, index) in sidebarLinks"
            :key="index"
            :to="link.path"
            :name="link.name"
            :icon="link.icon"
          ></SidebarLink>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import SidebarLink from "./SidebarLink";

export default defineComponent({
  name: "Sidebar",
  props: {
    title: {
      type: String,
      default: "Top Servis Okien",
    },
    backgroundColor: {
      type: String,
      default: "vue",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) =>
        ["primary", "info", "success", "warning", "danger"].includes(value),
    },
    sidebarLinks: {
      type: Array,
      default: () => [
        {
          name: "Dashboard",
          path: "/dashboard",
          icon: "tim-icons icon-chart-pie-36",
          isActive: () => window.location.pathname === "/dashboard",
        },
        {
          name: "Servisy",
          path: "/servisy",
          icon: "mdi mdi-account-wrench",
          isActive: () => window.location.pathname === "/servisy",
        },
        {
          name: "Obhliadky",
          path: "/obhliadky",
          icon: "mdi mdi-eye-outline",
          isActive: () => window.location.pathname === "/obhliadky",
        },
        {
          name: "Cenové ponuky",
          path: "/price-offers",
          icon: "mdi mdi-currency-eur",
          isActive: () => window.location.pathname === "/price-offers",
        },
        // {
        //   name: "Objednávky",
        //   path: "/orders",
        //   icon: "mdi mdi-cart",
        //   isActive: () => window.location.pathname === "/orders",
        // },
        {
          name: "Klienti",
          path: "/clients",
          icon: "mdi mdi-account",
          isActive: () => window.location.pathname === "/clients",
        },
        {
          name: "Produkty",
          path: "/products",
          icon: "mdi mdi-archive",
          isActive: () => window.location.pathname === "/clients",
        },
        {
          name: "Nastavenia",
          path: "/settings",
          icon: "mdi mdi-cog",
          isActive: () => window.location.pathname === "/settings",
        },
        // {
        //   name: 'Notifications',
        //   path: '/notifications',
        //   icon: 'tim-icons icon-bell-55',
        //   isActive: () => window.location.pathname === '/notifications',
        // },
        // {
        //   name: "Help",
        //   path: "/help",
        //   icon: "tim-icons icon-support-17",
        //   isActive: () => window.location.pathname === "/help",
        // },
        // {
        //   name: "Icons",
        //   path: "/icons",
        //   icon: "tim-icons icon-single-02",
        //   isActive: () => window.location.pathname === "/icons",
        // },
      ],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SidebarLink,
  },
  setup(props) {
    const linkHeight = ref(65);
    const activeLinkIndex = ref(0);
    const isRTL = ref(false); // Assuming $rtl.isRTL was a reactive property in Vue 2

    const findActiveLink = () => {
      props.sidebarLinks.forEach((link, index) => {
        if (link.isActive()) {
          activeLinkIndex.value = index;
        }
      });
    };

    watch(() => props.$route, findActiveLink, { immediate: true });

    return {
      linkHeight,
      activeLinkIndex,
      isRTL,
      findActiveLink,
    };
  },
});
</script>
